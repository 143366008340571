import React, { useEffect, useRef, useState } from 'react'
import { ErrorModal, SuccessModal, WarningModal } from '../../components/Modal'
import Progressbar from '../../components/Progress'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import Button from '../../components/Button'
import RecoveryStyle from './RecoveryStyle'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { customFuncsApi, logsUrl } from '../../api'
import moment from 'moment'
import ClipLoader from 'react-spinners/ClipLoader'
import { recovery as RecoveryStore } from '../../store/slices'
import { roundCurrency } from '../../utils'
import { API_ROOT_PROD, COMPANY_NAME } from '../../config'
import customMainApi from '../../api/index';

const Recovery = () => {
	const { getMe } = useSelector((state) => state.main)
	const {
		setName,
		setImei,
		setStartDate,
		setEndDate,
		setStatus: setStatusStore,
		setExecuter: setExecuterStore,
		setPage: setPageStore,
	} = RecoveryStore.actions
	const {
		name,
		imei: imeiStore,
		startDate,
		endDate,
		status: statusStore,
		executer: executerStore,
		currentPage: pageStore,
	} = useSelector((state) => state.recovery)

	const navigate = useNavigate()

	const startOfMonth = moment().clone().startOf('month')
	const endOfMonth = moment().clone().endOf('month')
	const startDateFormatted = startOfMonth.format('YYYY-MM-DD')
	const endDateFormatted = endOfMonth.format('YYYY-MM-DD')

	const errorRef = useRef()
	const successRef = useRef()
	const warningRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [isProtsent, setIsProtsent] = useState(false)
	const [allDebtorsLoading, setAllDebtorsLoading] = useState(false)

	const [status, setStatus] = useState(statusStore)
	const [clientName, setClientName] = useState(name)
	const [imei, setIMEI] = useState(imeiStore)
	const [userCardCode, setUserCardCode] = useState('')
	const [numberProtsent, setnumberProtsent] = useState(1)
	const [dateStart, setDateStart] = useState(startDate || startDateFormatted)
	const [dateEnd, setDateEnd] = useState(endDate || endDateFormatted)
	const [executer, setExecuter] = useState([])
	const [customerData, setCustomerData] = useState([])
	const [doceAndInsData, setDoceAndInsData] = useState([])
	const [progress, setProgress] = useState(0)
	const dispatch = useDispatch()
	const [count, setCount] = useState(0)

	const [currentPage, setCurrentPage] = useState({ page: pageStore })
	const [data, setData] = useState({
		data: [],
	})
	const [businessLoading, setBusinessLoading] = useState(false)
	const [userFields, setUserFields] = useState([])

	const [employeeID, setEmployeeID] = useState(
		executerStore?.length <= 0
			? executerStore
			: getMe?.Department2.Name === 'Undiruv'
				? getMe?.EmployeeID
				: executerStore,
	)
	const [executorLoading, setExecutorLoading] = useState(false)

	useEffect(() => {
		getAllGetterMoney()
		doceAndIns()
		userFieldsFn()
		if (pageStore === undefined) {
			dispatch(setPageStore(0))
		}
	}, [])

	const getAllGetterMoney = () => {
		setExecutorLoading(true)
		api
			.get(
				`$crossjoin(EmployeesInfo, Departments)?$filter=(Departments/Name eq 'Undiruv' or Departments/Name eq 'Undiruvchi1') and EmployeesInfo/Department eq Departments/Code&$expand=EmployeesInfo($select=EmployeeID,LastName,FirstName,U_CashAccount,U_CardAccount,U_TransAccount),Departments($select=Name,Code)`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=90000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				if (get(getMe, 'Department2.Name', '') === 'Undiruv') {
					const data = resData.filter(
						(v) => v.EmployeesInfo.EmployeeID === get(getMe, 'EmployeeID', ''),
					)
					setExecuter(data)
				} else setExecuter(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setExecutorLoading(false)
			})
	}

	const doceAndIns = () => {
		api
			.get(
				`Invoices?%24filter=DocumentStatus%20eq%20'bost_Open'&%24select=DocEntry`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=90000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setDoceAndInsData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const recovery = (
		startD,
		endD,
		userCardCode = name.includes(',') ? name.split(' , ')[1] : name,
	) => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`installments?date1='${startD === '' ? '2012-01-01' : startD}T00:00:00Z'&date2='${endD === '' ? moment().add(2, 'year').format('YYYY-MM-DD') : endD}T00:00:00Z'${clientName.split(' , ')[1] ? `&CardCode='${clientName.split(' , ')[1]}'` : ''}${status?.length <= 0 ? '' : `&status=${status}`}&$skip=${currentPage.page}${imei !== '' ? `&IntrSerial=%25${imei}%25` : ''}`,
				{
					params: {
						U_Dunner:
							get(getMe, 'Department2.Name', '') === 'Sherik' ||get(getMe, 'Department2.Name', '') === 'Sherik' ||
							employeeID?.length <= 0
								? undefined
								: employeeID,
					},
					headers: {
						Prefer: 'odata.maxpagesize=10',
					},
				},
			)
			.then((res) => {
				if (get(res, 'status', 0) === 401) navigate('/login', { replace: true })
				const resData = get(res, 'data.value', [])
				setData({ data: resData })
				setCount(res.data.count)
				setData({
					data: [...resData],
				})
				setIsLoading(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
				if (get(err, 'response.status', 0) === 401)
					navigate('/login', { replace: true })

				setIsLoading(false)
			})
	}

	const AddExecutorsForInstallments = async () => {
		setExecutorLoading(true)
		setIsProtsent(true)
		try {
			const installments = await api
				.get(
					`Invoices?%24filter=DocumentStatus%20eq%20'bost_Open'%20and%20U_Dunner%20eq%20null&%24select=DocEntry`,
					{
						headers: {
							Prefer: 'odata.maxpagesize=1000000',
						},
					},
				)
				.then((res) => JSON.parse(res.data).value)

			const chunkSize = 50
			console.log(installments)
			const totalInstallments = installments?.length

			// Function to send a chunk of installments
			const sendChunk = async (chunk) => {
				const chunkData = {
					invoices: chunk.map((v) => v.DocEntry),
					dunners: executer
						.filter((v) => v.Departments.Name !== 'Undiruvchi1')
						.map((v) => v.EmployeesInfo.EmployeeID),
				}
				await customFuncsApi.post('assignDunners', chunkData).then((res) => {
					// try {
					// 	logsUrl.post('apis', {
					// 		company_name: COMPANY_NAME,
					// 		method: 'post',
					// 		path: customFuncsApi + '/assignDunners',
					// 		description: 'sborida Распределение новых должников',
					// 		username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
					// 		body: JSON.stringify(chunkData),
					// 	})
					// } catch (err) {
					// 	errorRef.current?.open(
					// 		'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					// 	)
					// }
				})
			}
			console.log(installments, 'installments')
			// Iterate over installments in chunks
			for (let i = 0; i < totalInstallments; i += chunkSize) {
				const chunk = installments.slice(i, i + chunkSize)
				await sendChunk(chunk)
				let protsent = Math.floor((i / totalInstallments) * 100)
				setProgress(protsent)
			}
		} catch (error) {
			console.error('Error processing installments:', error)
		} finally {
			setExecutorLoading(false)
			setIsProtsent(false)
		}
	}

	const newDatas = () => {
		if (data.data?.length >= 10) {
			setCurrentPage({ page: currentPage.page + 10 })
			dispatch(setPageStore(currentPage.page + 10))
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const lastPage = () => {
		if (data.data?.length >= 10) {
			setCurrentPage({ page: count - (count % 10 ? count % 10 : 10) })
			dispatch(setPageStore(count - (count % 10 ? count % 10 : 10)))
		}
	}
	const firstPage = () => {
		setCurrentPage({ page: 0 })
		dispatch(setPageStore(0))
	}

	const oldData = () => {
		if (data.data.oldPage < 0) {
			alert('boshqa malumot yoq')
		} else {
			setCurrentPage({ page: currentPage.page - 10 })
			dispatch(setPageStore(currentPage.page - 10))
		}
	}

	const viewItem = (v) => {
		navigate('/viewRecoveryItem', {
			state: {
				data: v,
				executer,
			},
		})
	}

	useEffect(() => {
		if (get(getMe, 'Department2.Name', '') !== 'Sherik' || get(getMe, 'Department2.Name', '') !== 'Sotuv')
			recovery(dateStart, dateEnd)
		else if (!!clientName.split(' , ')[1] || !!imei)
			recovery(dateStart, dateEnd)
	}, [currentPage])

	const searchUser = (a = '') => {
		setClientName(a)
		// setUserCardCode(a.length <= 0 && "");
		setBusinessLoading(true)
		api
			.get(
				`BusinessPartners?$select=CardCode,CardName,Address,Phone1,GroupCode&$filter=CardType eq 'cCustomer' and (${get(getMe, 'Department2.Name', '') !== 'Sherik' || get(getMe, 'Department2.Name', '') !== 'Sotuv' ? `contains(CardName, '${a}') or` : ''} contains(U_Telephone, '${a}')) and Frozen eq 'tNO'`,
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				if (
					!(resData?.length === 1) ||
					!a.includes(resData[0].CardName) ||
					!a.includes(resData[0].CardCode)
				)
					setCustomerData(resData)
				if (
					resData?.length === 0 &&
					(!a.includes(resData[0].CardName) || !a.includes(resData[0].CardCode))
				) {
					setCustomerData([{ CardName: 'Нет данных', CardCode: '' }])
				}
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setBusinessLoading(false)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
					$select: 'Name,ValidValuesMD,TableName',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	// debounce

	useEffect(() => {
		const delay = 1000
		let timeoutId
		if (clientName) {
			timeoutId = setTimeout(() => {
				searchUser(clientName)
			}, delay)
		}
		return () => {
			clearTimeout(timeoutId)
		}
	}, [clientName])

	const handleChange = (e) => {
		const newSearchTerm = e.target.value
		if (e.target.value?.includes(' , ')) {
			setUserCardCode(e.target.value?.split(' , ')[1])
		} else {
			setUserCardCode('')
		}
		dispatch(setName(newSearchTerm))
		setClientName(newSearchTerm)
	}

	const distribution = async () => {
		setAllDebtorsLoading(true)
		setIsProtsent(true)
		try {
			const installments = await api
				.get(
					`Invoices?%24filter=DocumentStatus%20eq%20'bost_Open'&%24select=DocEntry`,
					{
						headers: {
							Prefer: 'odata.maxpagesize=1000000',
						},
					},
				)
				.then((res) => JSON.parse(res.data).value)

			console.log(installments)
			const chunkSize = 50
			const totalInstallments = installments?.length

			// Function to send a chunk of installments
			const sendChunk = async (chunk) => {
				const chunkData = {
					invoices: chunk.map((v) => v.DocEntry),
					dunners: executer
						.filter((v) => v.Departments.Name !== 'Undiruvchi1')
						.map((v) => v.EmployeesInfo.EmployeeID),
				}
				await customFuncsApi.post('assignDunners', chunkData).then((res) => {
					// try {
					// 	logsUrl.post('apis', {
					// 		company_name: COMPANY_NAME,
					// 		method: 'post',
					// 		path: customFuncsApi + '/assignDunners',
					// 		description: 'sborida Распределение всех должников',
					// 		username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
					// 		body: JSON.stringify(chunkData),
					// 	})
					// } catch (err) {
					// 	errorRef.current?.open(
					// 		'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					// 	)
					// }
				})
			}

			// Iterate over installments in chunks
			for (let i = 0; i < totalInstallments; i += chunkSize) {
				const chunk = installments.slice(i, i + chunkSize)
				console.log(chunk, 'chunk')
				await sendChunk(chunk)
				let protsent = Math.floor((i / totalInstallments) * 100)
				setProgress(protsent)
			}
		} catch (error) {
			console.error('Error processing installments:', error)
		} finally {
			setAllDebtorsLoading(false)
			setIsProtsent(false)
		}
	}

	const handleSearch = () => {
		setCurrentPage({ page: 0 })
		dispatch(setPageStore(0))
	}

	return (
		<Layout>
			<RecoveryStyle>
				<div className="container">
					<div style={{ width: '100%' }}>
						<p className="topTitle mb-8">График сбора</p>
						<div className="flex flex-col xl:flex-row xl:items-center gap-4 mt-10">
							<div className="flex-1 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
								<div className="relative">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Клиент
									</p>
									<input
										className={
											'border-[1px] border-[#d9d9d9] p-2 rounded-md w-full'
										}
										type="text"
										value={clientName}
										onChange={(e) => {
											handleChange(e)
										}}
									/>
									{clientName && (
										<div className="absolute top-15 left-0 bg-white w-full z-10 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
											{businessLoading ? (
												<ClipLoader size={10} />
											) : (
												customerData.map((v, i) => {
													return (
														<div
															key={i}
															className="p-2 border-b cursor-pointer hover:bg-gray-100"
															onClick={() => {
																if (v.CardName === 'Нет данных') return
																dispatch(
																	setName(`${v.CardName} , ${v.CardCode}`),
																)
																setUserCardCode(v.CardCode)
																setClientName(`${v.CardName} , ${v.CardCode}`)
																setCustomerData([])
																setDateStart('')
																setDateEnd('')
																dispatch(setStartDate(''))
																dispatch(setEndDate(''))
															}}
														>
															{v.CardName} , {v.CardCode}
														</div>
													)
												})
											)}
										</div>
									)}
								</div>
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										ИМEИ
									</p>
									<input
										type="text"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										value={imei}
										onChange={(e) => {
											setIMEI(e.target.value)
											dispatch(setImei(e.target.value))
										}}
										autocomplete="off"
									/>

									<datalist id="client">
										{customerData.map((v, i) => (
											<option
												key={i}
												value={`${get(v, 'CardName', '')} , ${get(
													v,
													'CardCode',
													'',
												)}`}
											/>
										))}
									</datalist>
								</div>

								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Дата начала
									</p>
									<input
										type="date"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										value={dateStart}
										onChange={(v) => {
											setDateStart(v.target.value)
											dispatch(setStartDate(v.target.value))
										}}
									/>
								</div>
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Дата окончания
									</p>
									<input
										type="date"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										value={dateEnd}
										onChange={(v) => {
											setDateEnd(v.target.value)
											dispatch(setEndDate(v.target.value))
										}}
									/>
								</div>
								<div className="flex flex-col">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Статус платежа
									</p>
									<select
										name="payed"
										id="payed"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full flex-1'
										}
										defaultValue={status}
										onChange={(v) => {
											setStatus(v.target.value)
											dispatch(setStatusStore(v.target.value))
										}}
									>
										<option value="all">Все</option>
										<option value="invoicePaidFullyUntilToday">
											{'Оплачено'}
										</option>
										<option value="invoiceNotPaidFullyUntilToday">
											{'Неоплаченный'}
										</option>
									</select>
								</div>
								{get(getMe, 'Department2.Name', '') !== 'Sherik' && (
									<div>
										<p className={'font-medium text-zinc-600 text-sm mb-1'}>
											Приёмщик
										</p>
										<select
											name="executer"
											id="executer"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											onChange={(v) => {
												setEmployeeID(v.target.value)
												dispatch(setExecuterStore(v.target.value))
											}}
											value={employeeID}
										>
											<option></option>
											{get(getMe, 'Department2.Name', '') !== 'Undiruv' ? (
												<option value="null">Без приёмщик</option>
											) : null}

											{executer.map((v, i) => {
												return (
													<option
														value={get(v, 'EmployeesInfo.EmployeeID', 0)}
														key={i}
														selected={
															v.EmployeesInfo.EmployeeID ===
															get(data, 'U_Dunner', '')
														}
													>
														{get(v, 'EmployeesInfo.FirstName', 'Name')}{' '}
														{get(v, 'EmployeesInfo.LastName', 'Surename')}
													</option>
												)
											})}
										</select>
									</div>
								)}
							</div>
							<Button isLoading={isLoading} onClick={() => handleSearch()}>
								Поиск
							</Button>
						</div>
						<div className="mt-8 mb-2 flex flex-col md:flex-row max-w-fit">
							<div className="flex gap-2 items-center">
								<div className="size-5 bg-red-200"></div>
								<span className={'whitespace-nowrap'}> {'<'} 60 день</span>
							</div>
							<div className="flex gap-2 items-center">
								<div className="size-5 bg-blue-700"></div>
								<span className={'whitespace-nowrap'}> {'<'} 90 день</span>
							</div>
							<div className="flex gap-2 items-center">
								<div className="size-5 bg-red-400"></div>
								<span className={'whitespace-nowrap'}> {'<'} 150 день</span>
							</div>
							<div className="flex gap-2 items-center">
								<div className="size-5 bg-black"></div>
								<span className={'whitespace-nowrap'}> {'>'} 150 день</span>
							</div>
							<p className={'whitespace-nowrap'}>
								{executorLoading
									? 'Загрузка данных, пожалуйста, подождите...'
									: ''}
							</p>
						</div>
						{isLoading ? (
							<ClipLoader
								loading={true}
								size={75}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						) : (
							<div className="overflow-y-auto">
								<table className="w-full text-sm text-left ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Код клиента
											</th>
											<th scope="col" className="px-6 py-4">
												Имя Клиента
											</th>
											<th scope="col" className="px-6 py-4">
												Товар
											</th>
											<th scope="col" className="px-6 py-4">
												Сумма
											</th>
											<th scope="col" className="px-6 py-4">
												Дата продажи
											</th>
											<th scope="col" className="px-6 py-4">
												Статус платежа
											</th>

											<th scope="col" className="px-6 py-4">
												Исполнитель
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.map((v, i) => {
											return (
												<tr
													key={i}
													onClick={() => viewItem(v)}
													className={
														get(v, 'oldestOverdueInsDays', -1) < 0
															? 'bg-green-200  text-gray-700  hover:border hover:border-zinc-400'
															: get(v, 'oldestOverdueInsDays', 0) < 60
																? 'bg-red-200   text-gray-700 hover:border hover:border-zinc-400 '
																: get(v, 'oldestOverdueInsDays', 0) < 90
																	? 'bg-blue-700 text-white hover:border hover:border-zinc-400'
																	: get(v, 'oldestOverdueInsDays', 0) < 150
																		? 'bg-red-400 text-white hover:border hover:border-zinc-400'
																		: 'bg-black text-white hover:border hover:border-zinc-00' +
																			' '
													}
												>
													<td className="px-6 py-4 font-medium">
														{get(v, 'CardCode', 'Код покупателя')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'CardName', 'Имя покупателя')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'Dscription', '')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'DocTotal', '').slice(0, -5)} USD
													</td>
													<td className="px-6 py-4">
														{get(v, 'DueDate', '').slice(0, 10)}{' '}
													</td>
													<td className="px-6 py-4">
														{get(v, 'U_Score', '') !== ''
											? userFields
													?.find((item) => item.Name === 'Score')
													?.ValidValuesMD.find(
														(value) =>
															get(v, 'U_Score', '') === value.Value,
													)?.Description || ''
											: ''}
													</td>
													<td className="px-6 py-4">
														{get(v, 'firstName', '')} {get(v, 'lastName', '')}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>

								<div className="between">
									<div className="flex items-center">
										<Button
											disabled={currentPage.page === 0}
											className={'btn'}
											onClick={oldData}
										>
											{'<'}
										</Button>
										<p className={'mr-2'}>{currentPage.page / 10 + 1}</p>
										<Button
											disabled={data.data?.length < 10}
											className={'btn'}
											onClick={newDatas}
										>
											{'>'}
										</Button>
										<Button
											disabled={currentPage.page === 0}
											className={'btn'}
											onClick={firstPage}
										>
											{'Первый'}
										</Button>
										<Button
											disabled={data.data?.length < 10}
											className={'btn'}
											onClick={lastPage}
										>
											{'Последний'}
										</Button>
									</div>
									<div className={'flex gap-4'}>
										{get(getMe, 'Department2.Name', '') !== 'Undiruv' &&
										doceAndInsData?.length > 1 &&
										get(getMe, 'Department2.Name', '') !== 'Sherik' ? (
											<Button
												className="btnSome"
												isLoading={executorLoading}
												onClick={AddExecutorsForInstallments}
											>
												{'Распределение новых должников'}
											</Button>
										) : null}
										{get(getMe, 'Department2.Name', '') !== 'Undiruv' &&
										doceAndInsData?.length > 1 &&
										get(getMe, 'Department2.Name', '') !== 'Sherik' ? (
											<Button
												className="btnSome"
												onClick={distribution}
												isLoading={isLoading || allDebtorsLoading}
												btnStyle={{ width: 300 }}
												hoverBtnStyle={{ width: 300 }}
											>
												{'Распределение всех должников'}
											</Button>
										) : null}
									</div>
								</div>
							</div>
						)}

						{isProtsent ? (
							<>
								<p className="progres">
									Не закройте окно пока процесс не завершится
								</p>

								<Progressbar bgcolor="green" progress={progress} height={23} />
							</>
						) : null}
					</div>
				</div>
			</RecoveryStyle>
			<>
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<SuccessModal getRef={(r) => (successRef.current = r)} />
				<WarningModal getRef={(r) => (warningRef.current = r)} />
			</>
		</Layout>
	)
}

export default Recovery